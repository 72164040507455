let isAnimating = false;

const accordionItems = document.querySelectorAll(
	'.wp-block-proactive-accordion-item'
);

accordionItems.forEach((entry) => {
	const header = entry.querySelector(
		'.wp-block-proactive-accordion-item__header'
	);

	if (header) {
		header.addEventListener('click', function () {
			const content = entry.querySelector(
				'.wp-block-proactive-accordion-item__content'
			);
			const expanded = entry.getAttribute('aria-expanded') === 'true';

			if (content) {
				if (expanded) {
					closeAccordionItem(this, content);
				} else {
					openAccordionItem(this, content);
				}
			}
		});
	}
});

const accordion = document.querySelector('.wp-block-proactive-accordion');

if (accordion) {
	accordion.addEventListener('keydown', function (event) {
		const header = event.target.querySelector(
			'.wp-block-proactive-accordion-item__header'
		);
		const content = header ? header.nextElementSibling : null;

		if (accordion.contains(document.activeElement)) {
			if (event.key === 'ArrowDown' && header && header.nextElementSibling) {
				event.preventDefault();
				header.nextElementSibling.focus();
			} else if (
				event.key === 'ArrowUp' &&
				header &&
				header.previousElementSibling
			) {
				event.preventDefault();
				header.previousElementSibling.focus();
			} else if (event.key === 'Enter' && header) {
				event.preventDefault();
				const expanded = event.getAttribute('aria-expanded') === 'true';
				if (expanded) {
					closeAccordionItem(header, content);
				} else {
					openAccordionItem(header, content);
				}
			}
		}
	});
}

function closeAccordionItem(header, content) {
	isAnimating = true;
	content.style.height = content.scrollHeight + 'px';
	setTimeout(function () {
		content.style.height = 0;
	}, 50);

	// Get content style transition duration
	const style = window.getComputedStyle(content);
	const duration = parseFloat(style.transitionDuration) * 1000;

	header.parentNode.setAttribute('aria-expanded', 'false');
	setTimeout(function () {
		content.setAttribute('hidden', '');
		isAnimating = false;
	}, duration);
}

function openAccordionItem(header, content) {
	if (isAnimating) return;

	const accordion = document.querySelector('.wp-block-proactive-accordion');
	const toggle = accordion.getAttribute('data-toggle');

	if (toggle === 'true') {
		closeOtherAccordionItems(header);
	}

	const iframe = content.querySelector('iframe');

	if (iframe) {
		window.addEventListener('message', function (event) {
			if (iframe.contentWindow === event.source) {
				// if has scrollbar
				if (
					iframe.scrollHeight < event.data ||
					iframe.scrollHeight > event.data
				) {
					content.style.height = `${event.data + 20}px`;
				}
			}
		});
	}

	const style = window.getComputedStyle(content);
	const duration = parseFloat(style.transitionDuration) * 1000;

	content.removeAttribute('hidden');
	content.style.height = content.scrollHeight + 'px';
	header.parentNode.setAttribute('aria-expanded', 'true');
	setTimeout(function () {
		content.style.height = 'auto';
	}, duration);
}

function closeOtherAccordionItems(clickedHeader) {
	const accordion = document.querySelector('.wp-block-proactive-accordion');
	const headers = accordion.querySelectorAll(
		'.wp-block-proactive-accordion-item__header'
	);

	headers.forEach(function (header) {
		if (header !== clickedHeader) {
			const content = header.nextElementSibling;
			closeAccordionItem(header, content);
		}
	});
}

// On window resize
window.addEventListener('resize', function () {
	const accordionItems = document.querySelectorAll(
		'.wp-block-proactive-accordion'
	);

	accordionItems.forEach((entry) => {
		const content = entry.querySelector(
			'.wp-block-proactive-accordion-item__content'
		);
		const inner = content.querySelector(
			'.wp-block-proactive-accordion-item__content-inner'
		);

		if (entry.getAttribute('aria-expanded') === 'true') {
			content.style.height = inner.clientHeight + 'px';
		}
	});
});

document.addEventListener('DOMContentLoaded', function () {
	// Set first accordion item to open if data-open is true
	const accordion = document.querySelector(
		'.wp-block-proactive-accordion-item'
	);

	if (accordion) {
		const firstItem = accordion.parentNode.dataset.first;

		const firstHeader = accordion.querySelector(
			'.wp-block-proactive-accordion-item__header'
		);
		const firstContent = accordion.querySelector(
			'.wp-block-proactive-accordion-item__content'
		);

		// Check anchor link to see if it matches an accordion item
		const hash = window.location.hash ? window.location.hash : false;
		const anchorItem = document.querySelector(`${hash}`)
			? document.querySelector(`${hash}`)
			: false;

		if (anchorItem && hash !== false) {
			const anchorHeader = anchorItem.querySelector(
				'.wp-block-proactive-accordion-item__header'
			);
			const anchorContent = anchorItem.querySelector(
				'.wp-block-proactive-accordion-item__content'
			);
			if (anchorHeader && anchorContent) {
				openAccordionItem(anchorHeader, anchorContent);
			}
		} else if (firstItem == 'true' && firstHeader && firstContent) {
			openAccordionItem(firstHeader, firstContent);
		}
	}
});
